.margin-0{
    margin: 0px !important;
}

.chatBtn{
    position: fixed;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
}

.ticketId_msg{
    display: flex;
    align-items: center;
    padding: 2px 30px;

}












.popup-message {
    position: fixed;
    bottom: 75px; /* Positioning from the bottom of the screen */
    right: 50px; /* Positioning from the right of the screen */
    background-color: var(--colorused);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: white;
    animation: blink 1s step-end infinite;
    text-align: center; /* Center text */
    z-index: 10; /* To make sure it's visible on top */
  }
  
  .popup-message::after {
    content: "";
    position: absolute;
    bottom: -10px; /* Positioning arrow below the popup */
    left: 84%;
    top: 43px;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: var(--colorused) transparent transparent transparent;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  
.excle_btn{
    background-color: #00615f;
    color: #fff;
    text-align: center;
    height: 40px;
    border-radius: 6px;
    border: none;
    max-width: 200px;
    padding: 0px 18px;
    cursor: pointer;
    width: 150px;
    white-space: nowrap;
    margin: 0px 5px;
}


/* ************** */


.custom-date-picker .MuiInputBase-root {
    color: black; /* Text color */
  }
  
  .custom-date-picker .MuiInputLabel-root {
    color: black; /* Label color */
  }
  
  .custom-date-picker .MuiSvgIcon-root {
    color: black; /* Calendar icon color */
  }
  
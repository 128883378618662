:root {
  --small: 12px;
  --medium: 16px;
  --fs-20: 20px;
  --large: 24px;
  --smMedium: 15px;

  --colorused: #064f40;
  --colorWhite: #fff;
  /* --colornewprimary: #ea4f6d;
  --colorblack: #000;
  --colorblue: #007bff;
  --colorprimary: #c20014;
  --colorborder: #e6e6e6;
  --colorcl2: #333;
  --colortextgray: #a9b1c0;
  --colorcl3: #666;
  --colorcl4: #999;
  --colorcl5: #222;
  --colorcl6: #888;
  --colorcl7: #b2b2b2;
  --colorcl8: #555;
  --colorcl9: #aaa;
  --colorcl10: #1d1d1d;
  --colorcl11: #f9ba48;
  --colorcl12: #ccc; */

  --fontsans: sans-serif;
  --fontroboto: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --fontmonospace: monospace;
  --fontpoppins: "Poppins", sans-serif;
  --fontLinearicons: Linearicons-Free;
  --fontSacramento: Sacramento;
  --fontjosefin: "Josefin Slab", serif;
  --fontMontserrat: "Montserrat", sans-serif;
  --fontsans: Linearicons-Free;
  --fontroboto: Linearicons-Free;
  --fontmonospace: Linearicons-Free;
  --fontpoppins: Linearicons-Free;
  --fontLinearicons: Linearicons-Free;
  --fontSacramento: Sacramento;
  --fontMontserrat: Linearicons-Free;
  --fontjosefin: Linearicons-Free;
  --fontsans: Montserrat Alternates, sans-serif !important;
  --fontroboto: Montserrat Alternates, sans-serif !important;
  --fontmonospace: Montserrat Alternates, sans-serif !important;
  --fontpoppins: Montserrat Alternates, sans-serif !important;
  --fontLinearicons: Montserrat Alternates, sans-serif !important;
  --fontSacramento: Sacramento;
  --fontMontserrat: Montserrat Alternates, sans-serif !important;
  --fontjosefin: Montserrat Alternates, sans-serif !important;

  --cui-body-bg: #ffffff; /* Define a default background color */
}





body {
  background-color: var(--cui-body-bg, #ffffff) !important;
}



.me-3 {
  font-size: 20px;
}

.btn.active {
  background-color: #55AE9C !important;
  color: #ffffff;
}
body{
  background-color: #ffffff !important;
  color: #000000 !important;
}
#root{
  background-color: #ffffff ;
  color: #000000;
}

html, body {
  background-color: var(--cui-body-bg); /* CoreUI default */
}

* {
  background-color: transparent; /* or a specific color */
}
body {
  background-color: #ffffff; /* Ensure the body has a white background */
}


.c-app {
  background-color: #ffffff !important;
}

@media (prefers-color-scheme: dark) {
  body {
      background-color: #ffffff; /* Force a white background */
  }
}
.ck-editor__main ul {
    padding: revert !important;
  }
  .ck-content {
    min-height: 250px;
    height: 50vh;
  }
  .ck-widget img {
    height: 150px;
  }
  
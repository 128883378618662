.m-t-10{
    margin-top: 10px;
}

.custom-column {
    max-height: 250px;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
  }

.custom-column_question{
    max-height: 200px !important;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
  }
  .form_control_lable{
    padding: 0px 5px;
  }
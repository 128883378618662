.id_navigate {
  cursor: pointer;
  color: blue;
  margin: 0px;
}

.viewsreen_container {
  width: 100%;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  border-radius: 10px;
}

.partneredithead {
  cursor: pointer;
}

input[type='file'] {
  color: transparent !important;
}

.partner_image_value {
  position: absolute;
  left: 8rem;
  top: 1rem;
  width: 50%;
  white-space: nowrap;
}

.longtextcss {
  word-break: break-word;
  max-width: 350px;
  white-space: normal;
}

/* .viewpagevalues {
  font-size: 16px;
  margin: 0;
  white-space: break-spaces;
  min-width: 200px !important;
  text-align: end;
} */
.viewpagevalues {
  font-size: 16px;
  margin: 0;
  white-space: break-spaces;
  min-width: 200px !important;
  text-align: end;
  word-break: break-word; /* Allows wrapping within words */
  overflow-wrap: break-word; /* Ensures long words wrap */
}
.view_membership_section {
  display: flex;
  gap: 50px;
}
.acordian_body_inside {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.view_membership_field {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: flex-start;
  /* flex-wrap: wrap; */
}
.location_view{
  width: 60% !important;
}
.location_class{
  padding: 2px;
}
a {
  color: var(--colorused) !important;
  text-decoration: none !important;
}
.headandback{
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  gap: 2px !important;
}
.MainAcoordien_live_view {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.view_membership_status {
  display: flex;
  justify-content: center;
}

.membership_detail {
  width: 98%;
  margin-left: 13px;
}

.detail_head {
  margin-top: 50px;
  margin-left: 13px;
  font-size: 24px;
}

.country_limit {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view_membership_field img {
  width: 70px;
}

.customer_demographic_email {
  display: flex;
  width: 48%;
  justify-content: space-between;
}

.editButtonclass {
  cursor: pointer;
}

.view_membership_field b {
  font-size: 16px;
}

.containerdivuser .editAndAddHeadClass {
  margin-top: 13px;
}


.partner_client {
  white-space: initial !important;
}

.partner_client p {
  max-width: 360px;
  word-break: break-all;
}

.modalFooter {
  margin-top: 25px !important;
  padding: 2px !important;
}

.btn_color {
  background-color: var(--colorused) !important;
  border: none !important;
  color: var(--colorWhite) !important;
  /* font-size: 13px !important; */
  overflow: hidden;
  white-space: nowrap;
  height: 38px;
  width: 75px;
}

.Status_dropdown {
  width: 400px !important;
}
.Status_dropdown:focus {
  /* border-color: transparent !important; */
  box-shadow: none !important;
}


.details_display_container {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0px;
}

.details_display_container_fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 49%;
}

.details_display_container_fluid img {
  height: 100px;
  width: 100px;
}
.view_image_container{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.view_image_container img{
    cursor: pointer;
}

.accordion-button:not(.collapsed) {
  color: #062f06 !important;
  font-size: 20px !important;
  background-color: #e5ebe5 !important;
  box-shadow: inset 0 calc(-1* var(--cui-accordion-border-width)) 0 var(--cui-accordion-border-color);
  font-weight: 500 !important;
}

.modalFooter {
    border: none !important; /* Remove border */
}


/* ****************** */
/* Target the wrapper list of tabs */


 /* .TabLink.active{
  border-bottom:  1px solid #00615f !important;
} */
.nav-tabs .nav-link{
  color :#00615f !important;
}
.TabList .TabLink{
  color: black;
  border-bottom:  2px solid #00615f !important;
}



.fassiEnddate{
  width: 100% !important;
  padding: 8px;
  height: 55px;
  font-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  color: #373737;
}








.inputfield_container_div{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.inputfield_container_div_inside{                                         
  width: 48%;
  min-width: 250px;
}
.inputfield_container_div_inside label{
  width: 100%;
  min-width: 250px;
}

.document_container {
  min-height: 100vh;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.dropzone_field{
  padding: 2px;
  width: 100%;
  height: 55px;
  border-radius: 4px;
  border: 1px solid #c9c9c9;
  background: #fff;
}

.Dropzone_Preview_container{
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
}
.Dropzone_Preview_container{
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
}
.product_image_lable{
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #757575;
}
.dropzone_container_Product label {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
  align-items: center !important;
  height: 55px;
  border: 1px solid #bcbbbb !important;
  border-radius: 4px !important;
  min-width: 300px !important;
  max-width: 100% !important;
}

.imageUploadClass{
  top: -16px;  
  width: 100%;  
}
.agree_text_document{
  margin: 10px 0px;
}

.dish_tag_container div{
min-height: 45px !important;
margin-top: 5px !important; 
}
.agree_text_document span {
  color: var(--colorused);
  cursor: pointer;
  font-weight: 500;
}
.delete_button{
  position: absolute;
  border: none;
  border-radius: 50%;
  padding: 2px;
  height: 25px;
  width: 25px;
  text-align: center;
  background: red;
  color: #fff;
  right: 0px;
  cursor: pointer
}
.single_image_contaoner{
  width: 100px ;
}
.preview_image{
  position: relative;
}

.rejectedText{
  color: red;
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
}

.verifiedText{
  color: green;
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
}
.fassiEnddate{
      width: 100% !important;
      padding: 8px;
      height: 55px;
      font-size: 16px;
      border: 1px solid #c4c4c4;
      border-radius: 6px;
      color: #373737;
}


.image_lable{

}

.dropzone_container_category label{
  margin-top: 5px;
  border: 1px solid gray !important;
  min-width: 300px !important;
  max-width: 100% !important;
}
.dropzone_container_category {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
}
.imageupload_btn{
  width: 100px;
  padding: 10px;
  border-radius: 6px;
  border: none;
  background-color: var(--colorused);
  color: #fff;
  cursor: pointer;
}
.image_lable{
  border: none !important;
  font-weight: 500;
  margin: 2px;
}
.preview_image{
  margin-top: 5px;
}
.preview_image img{
 border-radius: 6px;
}
/* Replace `.file-uploader-success-message` with the actual class or element that contains "Upload another" */
.file-uploader-success-message {
  display: none; /* Hides the "Upload another" text */
}
.checkbox_agreetext_container {
  align-items: flex-start;
  display: flex;
}
.checkbox_agreetext_container label {
  margin: 0px;
}
.view_membership {
  padding-left: 0;
  /* margin-top: 10px;
  width: 98%;
  padding: 0px 20px 20px 0px;
  border: 2px solid #ebebeb;
  border-radius: 7px;
  margin-left: 13px;
  margin-bottom: 20px; */
}

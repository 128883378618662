.form-text {
    margin-top: 5px !important;
    font-size: 0.875em !important;
    color: red !important;
}
.add_form_container{
    padding: 25px;
    background-color: #fff;
}
.AddEdit_Heading{
    margin: 0px 0px 15px 0px;
}
.field-width{
    width: 90% !important;
}
.search-feild{
    max-width: 90%;
    height: 35px;
    /* margin-top: 8px;np */
    border: 1px solid #b7b7b7;
    border-radius: 7px;
    font-size: 15px;
    width: 200px;
    background-color: #ffffff;
    padding: 2px 8px 2px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    
}
.search-feild input{
    border: none;
    padding: 0px 10px;
    height: 100%;
    max-width: 90%;
}
.search-field:focus {
    outline: none;
}

.btn_add_country{
    width: 115px;
    height: 35px;
    font-size: 15px;
}
.switch_row{
    padding-left: 5px;
    display: flex;
}


.loader-container_edit{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.periodDivClass p{
    margin-left: 10px;
    margin-bottom: -6px;
}

  .periodDivClassDropdown .MuiFormControl-root  .MuiInputBase-root .MuiSelect-select {
    padding: 8px;
  }
  
.typeAndPeriodClass{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px 20px 5px;
    flex-wrap: wrap;
}
.pie_card{
  height: 400px !important;
}

.bigger_component{
  width: 68%;
  min-width: 300px;
}

.smaller_component{
  width: 30%;
  min-width: 300px;
}
 .chart-pie-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap; 
}
.heightcard{
  height: 380px !important;
}

.dhasboard_date_container{
  padding: 5px;
  height: 40px;
  border-radius: 4px;
  outline: blue;
  border: 1px solid #bababa;

}
.dhasboard_date_container_main{
  display: flex;
  gap: 5px;
  margin-bottom: 8px;
}
.date_picker_label_dashboard{
  margin: 0 !important;
}

.typeAndPeriodClassContainer{
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
}

input.dashboard_date_container {
  padding: 6px;
  border-radius: 4px;
  border: 1px solid #bebebe;
}
.dont-permission-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.dont-permission-text p{
  font-size: 20px;
  font-weight: 400;}
.swal-button--danger {
    background-color: #193d40 !important;
  }
  
  .swal-button--danger:hover {
    background-color: #193d40 !important;
  }
  .pie_heading{
    font-size: 20px;
  }

  .Status_dropdown_dashboard{
    width: 100%;
    margin-top: 2px;
  }

  .pie_container_fluid{
    height: 260px;
  }
  .pie_container_fluid label{
   font-size: 14px;
   font-weight: 600;
  }
  .login_logo_image1{
    height: 50px;
    object-fit: contain;
  }
  .appbar_logo_with_text{
    height: 40px;
    object-fit: contain;
  }
.form-error {
  color: red;
}
.container_fluid {
  display: flex;
  flex-direction: column;
}

.fg_css {
  text-decoration: none;
  float: right;
}

.bglogin {
  position: relative; /* Ensure the element is positioned relative to the pseudo-element */
  background-image: url('../../../assets/images/bgimage.png'); 
  background-size: cover; 
  background-position: center;
  object-fit: contain;
  height: 100vh;
  box-sizing: border-box;
}

.bglogin::before {
  content: ""; /* Required to create the overlay */
  position: absolute; 
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Overlay color with 50% opacity */
  z-index: 1; /* Ensure it is on top of the background image */
}

.login_containrr{
  position: relative; /* Ensure the content is above the overlay */
  z-index: 2; /* Ensure the content is above the overlay */
  color: white; 
}


/* faq */

.TabList {
  position: relative;  
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.TabList::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; 
  height: 2px; 
  background-color: none; 
  z-index: -1; 
}
.textfield_background .form-control:focus {
  background-color: white !important; /* Set to white or any desired color */
  box-shadow: none !important; /* Remove any additional focus shadow */
  border-color: var(--colorused) !important; /* Optional: customize border color */
}
.Tab {
  position: relative; 
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  border-bottom: 5px solid transparent; 
  outline: none; 
  display: flex;
  align-items: center;
  gap: 5px;
}

.Tab:focus,
.Tab:hover {
  border-bottom-color: transparent; 
}

.Tab[aria-selected="true"] {
  border-bottom-color: #00615f;
  color: #00615f;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.TabPanel {
  padding: 20px;
  border: 1px solid #ccc;
  border-top: none;
}
.otp_input_type {
  text-align: center;
  outline: none;
  margin: 3px;
  padding: 5px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  border: 1px solid grey;
  border-radius: 6px;
  background-color: #fff;
  color: black;
}
.otp_screen_main_container{
  text-align: center !important;
}

.otpverify_resend {
  text-align: center;
  font-size: 26px;
}
.otpverify_resend_btn {
  text-align: center;
  font-size: 24px;
  border: none;
  color: grey;
  background-color: #fff;
}

.box {
  text-align: center;
  outline: none;
  margin: 3px;
  padding: 5px;
  width: 35px;
  height: 35px;
  font-size: 20px;
  border: 1px solid grey;
  border-radius: 6px;
}
.otpbox_container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.otpmsg {
  margin: 20px;
  text-align: center;
  color: grey;
  font-size: 24px;
  font-weight: 400;
}
.login-div h3 {
  font-size: 26px;
  color: #4b4646;
  font-weight: 500;
}

.creditcard {
  margin: auto;
  background-color: white;
  margin-top: 5%;
}


.Otp_form_box > div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
}